
import React, { } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { PSetPage } from "./pset/PSetPage.js"
import { PSetHome } from "./pset/PSetHome.js"
import { PSetPrint } from "./pset/PSetPrint.js"
import { Authenticated } from "./components/general/auth/Authenticated";
import { Profile } from "./profile/Profile.js"
import { Home } from './home/Home.js'
import { GradingHome } from './grading/GradingHome.js'
import { GradingPage } from './grading/GradingPage.js'
import { GradingStudent } from './grading/GradingStudent.js'
import PyodideProvider from './components/pyodide/PyodideProvider';
import { GradingStudentSummary } from './grading/GradingStudentSummary.js'
import { PSetHistory } from './pset/PSetHistory.js'
import { HomeRedirect } from "HomeRedirect.js";
import { PublicHome } from "public/publichome/PublicHome.js";
import { PublicCoursePage } from "public/PublicCoursePage.jsx";
import { Music } from "music/Music.js";



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<>Error</>}>


      {/* 
        TODO: I believe there is an option to pull 
        :qtrId out into the outer router and then start pre
        loading while the user is authenticating.... 
      */}

      <Route element={<Authenticated component={Outlet} />}>
        {/* <Route path="/public" element={<PublicCoursePage />}>
          <Route index element={<PublicHome />} />
        </Route> */}
        <Route path="/:qtrId/music" element={<Music />} />
        <Route path="/grading/:qtrId/:psetId/:qId/:studentId" element={<GradingStudent />} />
        <Route path="/grading/:qtrId/:psetId/:qId" element={<GradingPage />} />
        <Route path="/grading/:qtrId/:psetId" element={<GradingHome />} />
        <Route path="/studentSummary/:qtrId/:psetId/:qId" element={<GradingStudentSummary />} />
        <Route path="/history/:qtrId/:psetId/:qId/:forcedStudentId?" element={<PSetHistory />} />
        <Route path="/:qtrId/:psetId/print" element={<PSetPrint />} />
        <Route path="/:qtrId/:psetId/:qId/:forcedStudentId?" element={<PSetPage />} />
        <Route path="/:qtrId/:psetId/" element={<PSetHome />} />
        <Route path="/:qtrId" element={<Home />} />
        <Route path="/" element={<HomeRedirect />} />
      </Route>
    </Route>
  )
);

export const Router = () => {
  return <RouterProvider router={router} />;
};

